import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import SEO from '../components/Seo'
import Typography from '../components/Typography'
import Img from "gatsby-image"

import compStyles from '../pageComponents/contactUs/contactUs.module.scss'

const EnquiryPage = (props) => {
  const contactUs = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_purple.png" }) {
        childImageSharp {
          fluid(maxWidth: 240 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      facebook: file(relativePath: { eq: "social/facebook.png" }) {
        childImageSharp {
          fixed(width: 32 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      instagram: file(relativePath: { eq: "social/instagram.png" }) {
        childImageSharp {
          fixed(width: 32 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      youtube: file(relativePath: { eq: "social/youtube.png" }) {
        childImageSharp {
          fixed(width: 40 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
  <Layout>
    <SEO title='Contact Us' />
      <section className={compStyles.section}>
        <Typography variant="h3">CONTACT US</Typography>
        <div className={compStyles.container}>
          <div className={compStyles.space}>
            <Typography variant="h3">GET IN TOUCH</Typography>
          </div>
          <Img fluid={contactUs.logo.childImageSharp.fluid} className={compStyles.logo} alt="sound garage"/>
          <div className={compStyles.space}>
            <Typography variant="h3"><a href="tel:+919779660098" className={compStyles.link}>Ph: +91 97796 60098</a></Typography>
            <Typography variant="h3"><a href="tel:+919779660098" className={compStyles.link}>Email id: info@soundgarage.in</a></Typography>
          </div>
          <div className={compStyles.subsection}>
            <Typography variant="h3" className={compStyles.mainText}>For updates & information follow us on these platforms:</Typography>
            <div className={compStyles.socialContainer}>
              <Link to="https://www.facebook.com/SoundGarageChennai/" className={compStyles.social}><Img fixed={contactUs.facebook.childImageSharp.fixed} /></Link>
              <Link to="https://www.instagram.com/soundgarage.in/" className={compStyles.social}><Img fixed={contactUs.instagram.childImageSharp.fixed} /></Link>
            </div>
            <Typography variant="h3" className={compStyles.mainText}>/soundgarage.in</Typography>
          </div>
        </div>
      </section>
  </Layout>
  )
}

export default EnquiryPage
